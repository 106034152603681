<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Informasi Data User">
        <b-row class="mt-2 mb-2" align="end">
          <b-col md="3">
            <b-form-input
              id="name"
              v-model="name"
              placeholder="Cari Nama"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="3" align="start"> </b-col>

          <b-col md="3"></b-col>

          <b-col align="end" md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showModalTambah()"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Tambah</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'created_date'">
              {{ props.row.created_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'create_time'">
              {{ props.row.create_time.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'block_sold_count'">
              {{
                props.row.block_sold_count + props.row.block_installment_count
              }}
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <!-- <div class="demo-inline-spacing"> -->

              <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-tooltip.hover.v-primary
                    title="Detail"
                    class="btn-icon mr-2"
                    @click="showModalDetail(props)"
                  >
                    <feather-icon icon="SearchIcon" class="cursor-pointer" />
                  </b-button> -->

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                v-b-tooltip.hover.v-warning
                title="Ubah"
                class="btn-icon mr-2"
                @click="showModalUbah(props)"
              >
                <feather-icon icon="EditIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                v-b-tooltip.hover.v-danger
                title="Hapus"
                class="btn-icon"
                @click="deleteInfo(props)"
              >
                <feather-icon icon="Trash2Icon" class="cursor-pointer" />
              </b-button>
              <!-- </div> -->
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <!-- modal Form Kavling-->
        <b-modal
          id="modal-detail-notifikasi"
          ref="ref-form-data-user"
          :title="titleModal"
          hide-footer="true"
          size="lg"
          no-close-on-backdrop
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group label-for="name">
                    <template v-slot:label>
                      Nama <span class="text-danger">*</span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="formSubmit.name"
                        placeholder="Masukkan Nama"
                      />

                      <small class="text-danger">{{
                        errors[0] ? "Nama Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label-for="name">
                    <template v-slot:label>
                      Username <span class="text-danger">*</span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      rules="required"
                    >
                      <b-form-input
                        id="Username"
                        v-model="formSubmit.username"
                        placeholder="Masukkan Username"
                      />

                      <small class="text-danger">{{
                        errors[0] ? "Username Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label-for="name">
                    <template v-slot:label>
                      Email <span class="text-danger">*</span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required"
                    >
                      <b-form-input
                        id="Email"
                        v-model="formSubmit.email"
                        placeholder="Masukkan Email"
                        type="email"
                        @input="emailInput()"
                      />

                      <small class="text-danger">{{
                        errors[0] ? "Email Wajib Diisi" : ""
                      }}</small>

                      <small class="text-danger" v-if="errorsEmail">
                        Email Harus Valid
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label-for="name">
                    <template v-slot:label>
                      Posisi <span class="text-danger">*</span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="Posisi"
                      rules="required"
                    >
                    <v-select
                      v-model="formSubmit.role"
                      :options="roleOptions"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      placeholder="Pilih Posisi/Jabatan"
                    />

                      <small class="text-danger">{{
                        errors[0] ? "Posisi Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="this.aksi === `ubahData`">
                  <b-form-group label-for="name" label="Password">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="Password"
                        v-model="formSubmit.password"
                        :type="passwordFieldType"
                        name="Password"
                        placeholder="Masukkan Password"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="this.aksi === `tambahData`">
                  <b-form-group label-for="name">
                    <template v-slot:label>
                      Password <span class="text-danger">*</span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="Password"
                          v-model="formSubmit.password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="Password"
                          placeholder="Masukkan Password"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? "Password Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>

            <b-row class="mt-1 mb-2">
              <b-col align="end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="kembaliModalForm()"
                  class="mr-2"
                >
                  Batal
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="submitForm()"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-modal>
        <!-- end modal form kavling -->

        <!-- modal detail Form Kavling-->
        <b-modal
          id="modal-detail-notifikasi"
          ref="ref-detail-data-kavling"
          title="Form Detail Data Kavling"
          hide-footer="true"
          size="lg"
          no-close-on-backdrop
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group label-for="name">
                    <template v-slot:label> Nama Kavling </template>
                    <b-form-input
                      id="name"
                      v-model="formSubmit.name"
                      placeholder="Masukkan Nama Kavling"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label-for="mc-first-name">
                    <template v-slot:label> Provinsi </template>

                    <b-form-input
                      id="name"
                      v-model="formSubmit.province"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Kabupaten/ Kota"
                    label-for="mc-last-name"
                  >
                    <b-form-input
                      id="name"
                      v-model="formSubmit.city"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Kecamatan" label-for="mc-city">
                    <b-form-input
                      id="name"
                      v-model="formSubmit.district"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Kelurahan/ Desa" label-for="mc-city">
                    <b-form-input
                      id="name"
                      v-model="formSubmit.village"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Alamat" label-for="mc-country">
                    <b-form-textarea
                      rows="3"
                      v-model="formSubmit.address"
                      placeholder="Masukkan Alamat Lengkap"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Catatan" label-for="mc-country">
                    <b-form-textarea
                      rows="3"
                      v-model="formSubmit.note"
                      placeholder="Masukkan Catatan"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>

            <b-row class="mt-1 mb-2">
              <b-col align="end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="kembaliModalDetail()"
                  class="mr-2"
                >
                  Kembali
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-modal>
        <!-- end modal detail form kavling -->
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      //here
      file: null,

      required,
      email,
      roleOptions: [],

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
          width: "70px",
        },
        {
          label: "Nama",
          field: "name",
          thClass: "text-center",
          tdClass: "text-left",
          width: "250px",
        },
        {
          label: "Username",
          field: "username",
          thClass: "text-center",
          tdClass: "text-left",
          width: "250px",
        },
        {
          label: "Email",
          field: "email",
          thClass: "text-center",
          tdClass: "text-left",
          width: "250px",
        },
        {
          label: "Posisi",
          field: "role",
          thClass: "text-center",
          tdClass: "text-left",
          width: "250px",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],
      rows: [],

      tanggal_filter: "",

      configs: {
        mode: "range",
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      errorsEmail: false,

      formSubmit: {
        name: "",
        username: "",
        email: "",
        password: "",
        role: "",
      },

      titleModal: "",

      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
      desaOptions: [],

      idProvTemp: "",
      idKabTemp: "",
      idKecTem: "",

      // old delete
      searchTerm: "",

      name: "",
      sort: "",

      create_time: "",

      getBerkas: {},

      show: false,
      aksi: "",
      id_user: "",

      nama: "",

      selected: null,
    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
    this.getProvinsi();
    this.getRoles();
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search0 = "";
      if (this.name) {
        search0 = "&name=" + this.name;
      } else {
        search0;
      }

      return new Promise(() => {
        axios
          .get(
            "v1/user/all?" +
              "page=" +
              page +
              "&size=" +
              size +
              "&sort_by=create_time&sort_dir=-1" +
              search0
          )
          .then((res) => {
            this.rows = res.data.content;

            this.paging.page = res.data.page;
            this.paging.size = res.data.size;
            this.paging.total_elements = res.data.total_elements;
            this.paging.total_pages = res.data.total_pages;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalTambah() {
      this.aksi = "tambahData";

      this.formSubmit.name = "";
      this.formSubmit.username = "";
      this.formSubmit.email = "";
      this.formSubmit.password = "";
      this.formSubmit.role = "";

      this.titleModal = "Form Tambah User";

      this.$refs["ref-form-data-user"].show();
    },

    showModalUbah(props) {
      return new Promise(() => {
        axios
          .get("v1/user/" + props.row._id)
          .then((res) => {
            this.id_user = res.data._id;
            this.formSubmit.name = res.data.name;
            this.formSubmit.username = res.data.username;
            this.formSubmit.email = res.data.email;
            this.formSubmit.password = res.data.password;
            this.formSubmit.role = res.data.role;

            this.titleModal = "Form Update User";

            this.$refs["ref-form-data-user"].show();
            this.aksi = "ubahData";

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    kembaliModalForm() {
      this.$refs["ref-form-data-user"].hide();
      this.show = false;
    },

    formSubmit: {
      name: "",
      username: "",
      email: "",
      password: "",
      role: null,
    },

    submitForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (
            this.formSubmit.name === "" ||
            this.formSubmit.username === "" ||
            this.formSubmit.email === "" ||
            this.formSubmit.password === "" ||
            this.formSubmit.role === "" ||
            this.errorsEmail === true
          ) {
            return;
          } else {
            if (this.aksi === "tambahData") {
              return new Promise(() => {
                axios
                  .post("v1/user", this.formSubmit)
                  .then((res) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Tambah Data Berhasil`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Tambah Data User Berhasil`,
                      },
                    });
                    this.getData(this.paging.size, this.paging.page);
                    this.$refs["ref-form-data-user"].hide();
                    this.show = false;
                  })
                  .catch((error) => {
                    if (error.response.status == 401) {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.show = false;
                      this.$router.push("/login");
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.show = false;
                    }
                    this.show = false;
                  });
              });
            } else {
              return new Promise(() => {
                axios
                  .put("v1/user/" + this.id_user, this.formSubmit)
                  .then((res) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Ubah Data Berhasil`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Ubah Data User Berhasil`,
                      },
                    });
                    this.getData(this.paging.size, this.paging.page);
                    this.$refs["ref-form-data-user"].hide();
                    this.show = false;
                  })
                  .catch((error) => {
                    if (error.response.status == 401) {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.show = false;
                      this.$router.push("/login");
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.show = false;
                    }
                    this.show = false;
                  });
              });
            }
          }
        }
      });
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/user/" + props.row._id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data User Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    getProvinsi() {
      return new Promise(() => {
        axios.get("v1/region/getAllProvinces").then((res) => {
          this.provinsiOptions = res.data;
        });
      });
    },

    pilihProv(event) {
      this.formSubmit.province = event;
      this.getKabupaten();
    },

    getKabupaten() {
      var indexProv = this.provinsiOptions.findIndex(
        (x) => x.name === this.formSubmit.province
      );

      if (indexProv != null) {
        var provId = this.provinsiOptions[indexProv].id;

        return new Promise(() => {
          axios.get("v1/region/getRegencies/" + provId).then((response) => {
            this.kabupatenOptions = response.data;
          });
        });
      }
    },

    pilihKab(event) {
      this.formSubmit.regency = event;
      this.getKecamatan();
    },

    getKecamatan() {
      var indexKab = this.kabupatenOptions.findIndex(
        (x) => x.name === this.formSubmit.regency
      );
      if (indexKab != null) {
        var kabupatenId = this.kabupatenOptions[indexKab].id;

        return new Promise(() => {
          axios
            .get("v1/region/getDistricts/" + kabupatenId)
            .then((response) => {
              this.kecamatanOptions = response.data;
            });
        });
      }
    },

    pilihKec(event) {
      this.formSubmit.district = event;
      this.getDesa();
    },

    getDesa() {
      var indexKec = this.kecamatanOptions.findIndex(
        (x) => x.name === this.formSubmit.district
      );
      if (indexKec != null) {
        var kecamatanId = this.kecamatanOptions[indexKec].id;

        return new Promise(() => {
          axios.get("v1/region/getVillages/" + kecamatanId).then((response) => {
            this.desaOptions = response.data;
          });
        });
      }
    },

    pilihDesa(event) {
      this.formSubmit.village = event;
    },
    // end region

    // get Temporary
    getTempProv() {
      return new Promise(() => {
        axios.get("v1/wilayah/getAllProvinces").then((response) => {
          this.provinsiOptions = response.data;

          let indexProv = this.provinsiOptions.findIndex(
            (x) => x.name === this.formSubmit.province
          );

          return new Promise(() => {
            axios.get("/v1/wilayah/getAllProvinces").then((response) => {
              this.idProvTemp = response.data[indexProv].id;

              this.getTempKab();
            });
          });
        });
      });
    },

    // untuk mendapatkan kabupaten dari get id area
    getTempKab() {
      return new Promise(() => {
        axios
          .get("/v1/wilayah/getRegencies/" + this.idProvTemp)
          .then((response) => {
            this.kabupatenOptions = response.data;

            let indexKab = this.kabupatenOptions.findIndex(
              (x) => x.name === this.formSubmit.regency
            );

            return new Promise(() => {
              axios
                .get("/v1/wilayah/getRegencies/" + this.idProvTemp)
                .then((response) => {
                  this.idKabTemp = response.data[indexKab].id;
                  this.getTempKec();
                });
            });
          });
      });
    },

    getTempKec() {
      return new Promise(() => {
        axios
          .get("/v1/wilayah/getDistricts/" + this.idKabTemp)
          .then((response) => {
            this.kecamatanOptions = response.data;
          });
      });
    },

    emailInput() {
      const validRegex =
        // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (this.formSubmit.email === "") {
        this.errorsEmail = false;
      } else {
        if (this.formSubmit.email.match(validRegex)) {
          this.errorsEmail = false;
        } else {
          this.errorsEmail = true;
        }
      }
    },

    getRoles() {
      return new Promise(() => {
        axios.get("v1/employee/combo/position").then((res) => {
          this.roleOptions = res.data;
        });
      });
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-auth.scss";
</style>
